
























































import { Component, Vue } from 'vue-property-decorator'
import { Routes } from '@/shared/router'
import User from '@/shared/modules/user/user'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import { AuthActions } from '@/shared/store/auth/auth.actions'
import Client from '@/shared/modules/client/client'
import http from '@/shared/http'
import can from '@/shared/helpers/can'
import { Permission } from '@/shared/configs/permission.config'

@Component({
  methods: { can }
})
export default class Header extends Vue {
  Permission = Permission
  Routes = Routes

  logout() {
    this.$store.dispatch(AuthActions.logout)
      .then(() => this.$router.push({ name: Routes.login }).catch(() => {}))
  }

  async setCurrentClient(item: Client) {
    await http.post('select-client', {
      client_uuid: item.uuid
    })

    window.location.reload()
  }

  redirectToProfile() {
    this.$router.push({ name: Routes.profile }).catch(() => {})
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get selectedClient(): Client {
    return this.user.selected_client || this.user.clients[0]
  }
}
