













import { Component, Vue } from 'vue-property-decorator'
import User from '@/shared/modules/user/user'
import { AuthGetters } from '@/shared/store/auth/auth.getters'

@Component
export default class Footer extends Vue {
  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }
}
