










import { Component, Vue } from 'vue-property-decorator'
import User from '@/shared/modules/user/user'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import Layout from './Layout.vue'
import { Routes } from '@/shared/router'
import GlobalDialog from '@/components/dialogs/GlobalDialog.vue'
import Snackbar from '@/components/snackbars/Snackbar.vue'
import { DialogInterface } from '@/shared/interfaces/dialog.interface'
import { GlobalGetters } from '@/shared/store/global/global.getters'

@Component({
  components: { Snackbar, GlobalDialog, Layout }
})
export default class AppLayout extends Vue {
  created() {
    if (! this.user) {
      (! this.$router.currentRoute.meta.notRedirectApp) && this.$router.push({ name: Routes.login }).catch(() => {})
    } else {
      this.$router.currentRoute.meta.notRedirectApp && this.$router.push({ name: Routes.app }).catch(() => {})
    }
  }

  get user(): User | null {
    return this.$store.getters[AuthGetters.getUser]
  }

  get dialog(): DialogInterface {
    return this.$store.getters[GlobalGetters.getDialog]
  }
}
