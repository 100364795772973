import Model from '@/shared/interfaces/model'
import User from '@/shared/modules/user/user'
import store from '@/shared/store'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import _ from 'lodash'

const hasDynamicPermissions = (dynamicPermissions: any, permissions: string[], atLeastOne: boolean = false): boolean => {
  const hasPermission = (permission: string) => {
    const short = permission.split('.')[permission.split('.').length - 1]
    if (dynamicPermissions.hasOwnProperty(short)) {
      return !! dynamicPermissions[short]
    }

    return can([permission])
  }

  if (atLeastOne) return permissions.some(hasPermission)

  return permissions.every(hasPermission)
}

const hasPermissionIncludingAll = (permission: string, permissions: string[]): boolean => {
  if (Object.keys(permissions).includes('*')) {
    if (_.has(permissions, permission)) {
      return _.get(permissions, permission)
    }

    return true
  }

  return _.get(permissions, permission)
}

const can = (permissions: string[], atLeastOne: boolean = false, entry: Model | null = null): boolean => {
  if (! permissions) return true

  const user: User = store.getters[AuthGetters.getUser]

  if (! user) return false

  if (entry && _.has(entry, 'dynamic_permissions')) {
    return hasDynamicPermissions(entry.dynamic_permissions, permissions, atLeastOne)
  }

  if (Object.keys(user.permissions).includes('*') && Object.keys(user.permissions).length === 1) return true

  if (atLeastOne) return permissions.some((permission: string) => hasPermissionIncludingAll(permission, user.permissions))

  return permissions.every((permission: string) => hasPermissionIncludingAll(permission, user.permissions))
}

export default can
