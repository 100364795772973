


















import { Component, Vue } from 'vue-property-decorator'
import { DialogInterface } from '@/shared/interfaces/dialog.interface'
import { GlobalGetters } from '@/shared/store/global/global.getters'
import { GlobalActions } from '@/shared/store/global/global.actions'

@Component
export default class GlobalDialog extends Vue {
  get dialog(): DialogInterface {
    return this.$store.getters[GlobalGetters.getDialog]
  }

  closeDialog(): void {
    this.$store.dispatch(GlobalActions.closeDialog)
  }
}
