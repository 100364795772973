









import { Component, Vue } from 'vue-property-decorator'
import { MutationPayload } from 'vuex/types'
import SnackbarInterface from '@/shared/interfaces/snackbar.interface'
import { GlobalGetters } from '@/shared/store/global/global.getters'
import { GlobalMutations } from '@/shared/store/global/global.mutations'
import { GlobalActions } from '@/shared/store/global/global.actions'

@Component
export default class Snackbar extends Vue {
  snackbar: SnackbarInterface | null = this.$store.getters[GlobalGetters.getSnackbar]

  created() {
    this.$store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === GlobalMutations.setSnackbar) {
        this.snackbar = this.$store.getters[GlobalGetters.getSnackbar]

        if (this.snackbar) setTimeout(this.closeSnackbar, 5000)
      }
    })
  }

  closeSnackbar() {
    this.$store.dispatch(GlobalActions.showSnackbar, null)
  }
}
