export const Permission = {
  calculators: {
    create: 'calculators.create',
    update: 'calculators.update',
    newVersion: 'calculators.newVersion',
  },
  parameters: {
    create: 'parameters.create',
    update: 'parameters.update',
  },
  dataTables: {
    create: 'dataTables.create',
    update: 'dataTables.update',
  },
  rules: {
    create: 'rules.create',
    update: 'rules.update',
  },
  steps: {
    create: 'steps.create',
    update: 'steps.update',
    delete: 'steps.delete',
  },
  testSets: {
    create: 'testingSets.create',
    update: 'testingSets.update',
    delete: 'testingSets.delete',
  },
  clients: {
    index: 'clients.index',
    assignUser: 'clients.assignUser',
  },
  users: {
    index: 'users.index',
  }
}
