









import { Component, Vue } from 'vue-property-decorator'
import Header from '@/layouts/components/Header.vue'
import Footer from '@/layouts/components/Footer.vue'

@Component({
  components: { Footer, Header }
})
export default class Layout extends Vue {}
